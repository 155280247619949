import { ContactI } from "../../interfaces";

export const contactData: ContactI = {
    'tittle': 'Contáctanos',
    'contact': {
        'info': [
            {
                'name': 'contact 1',
                'info': '(+57) 314 2337375',
                'link': ''
            },
            {
                'name': 'contact 2',
                'info': '(+57) 312 4457480',
                'link': ''
            },
            {
                'name': 'mail',
                'info': 'info@symbtechsolutions.com',
                'link': ''
            }
        ],
        'socialmedia': [
            {
                'name': 'Whatsapp',
                'info': '(+57) 314 2337375',
                'link': 'https://api.whatsapp.com/send?phone=573142337375&text=Hola,%20me%20gustar%C3%ADa%20concretar%20una%20reuni%C3%B3n%20contigo'
            },
            {
                'name': 'Gmail',
                'info': 'info@symbtechsolutions.com',
                'link': 'mailto:/info@symbtechsolutions.com'
            },
            {
                'name': 'GitHub',
                'info': 'GitHub',
                'link': 'https://github.com/Symbtech-Solutions'
            }
        ]
    },
    'form': [
        {
            'label': 'Nombre',
            'name': 'name',
            'type': 'text',
            'placeholder': 'Nombre',
            'required': true
        },
        {
            'label': 'Apellido',
            'name': 'lastname',
            'type': 'text',
            'placeholder': 'Apellido',
            'required': true
        },
        {
            'label': 'Empresa',
            'name': 'company',
            'type': 'text',
            'placeholder': 'Empresa',
            'required': false
        },
        {
            'label': 'Telefono',
            'name': 'phone',
            'type': 'text',
            'placeholder': 'Telefono',
            'required': false
        },
        {
            'label': 'Correo',
            'name': 'mail',
            'type': 'email',
            'placeholder': 'Correo',
            'required': true
        },
        {
            'label': 'Mensaje',
            'name': 'message',
            'type': 'textarea',
            'placeholder': 'Mensaje',
            'required': true
        }
    ]
}