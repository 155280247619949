import { NavbarItemI } from "../../interfaces";

export const navValues: NavbarItemI[] = [
    {
        "name": "Nosotros",
        "link": "about"
    },
    {
        "name": "Servicios",
        "link": "services"
    },
    {
        "name": "Portafolio",
        "link": "portafolio"
    },
    {
        "name": "Contactanos",
        "link": "contact"
    }
]