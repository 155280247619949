import { SET_LOADING } from "./actionTypes";

const setLoadingAction = (loadingSelected) => async (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: {
      loadingSelected
    },
  });
};

export default setLoadingAction;
