import { Home, Service, AboutUs, Portafolio, Contact } from './pages'
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Provider } from "react-redux";
import { store } from "./redux/store";

import { Loading } from './atoms'

function App() {
  return (
    <Provider store={store}>
      <Loading />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<AboutUs />}></Route>
        <Route path="/services" element={<Service />}></Route>
        <Route path="/portafolio" element={<Portafolio />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
      </Routes>
    </Provider>
  );
}

export default App;
