import { useState } from 'react';
import { ListItem } from '../../atoms';
import { navValues } from '../../utils/json/Navbar'
import './style.css'

export function NavBarComponent(): JSX.Element {

    const [isSideMenuOpen, setisSideMenuOpen] = useState(false)

    function SideMenu(): JSX.Element {
        return (
            <div className="fixed h-screen w-2/4 bg-backgrondPrimay top-0 left-0">
                <ul className="menu-list flex flex-col text-xs font-bold">
                    {navValues.map((item, index) => {
                        return <ListItem key={index} name={item.name} link={item.link} size="20px" />
                    })
                    }
                </ul>
            </div>
        )
    }

    return (
        <div className="w-full h-12 text-primaryColor flex flex-row justify-between items-center px-11 py-8">
            <div className="brand-logo text-sm font-bold px-2">SymbTech</div>
            <ul className="hidden menu-list lg:flex lg:flex-row font-poppins font-bold justify-between w-2/5">
                {navValues.map((item, index) => {
                    return <ListItem key={index} name={item.name} link={item.link} size="22px" />
                })
                }
            </ul>
            <div className="w-4"></div>
            <button onClick={() => { setisSideMenuOpen(!isSideMenuOpen) }} className="lg:hidden menu-button">
                <svg className="fill-current h-4 w-4" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <title>Menu</title>
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
            </button>
            {(isSideMenuOpen) ? SideMenu() : ''}
        </div>
    );
}
