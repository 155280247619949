import { technologyData } from '../../utils/json/technology'
import { ParagraphTittle, ImageInfinityScroll } from '../../atoms'

import semiColonBackround from '../../assets/backgrounds/SemiColonDegradado.png'

import './style.css'

export function TechnologiesComponent(): JSX.Element {
    let image: string[] = []
    technologyData.technology.forEach((item) => {
        let img = require(`../../assets/img/technologies/${item.img}`)
        image.push(img.default)
    })
    return (
        <div className="container my-16">
            <div className="backgroundTechnology">
                <img id="imgSemiColon-2" src={semiColonBackround} alt="backround" />
            </div>
            <div className="text-center w-1/2 mx-auto">
                <ParagraphTittle tittle={technologyData.tittle} tittleSize="2.5rem" text={technologyData.text} textSize="18px" />
            </div>
            <ImageInfinityScroll image={image} />
        </div>
    )
}