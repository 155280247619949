import { ItemI } from "../../interfaces"

export function ListItemAtom(props: ItemI): JSX.Element {
    let { link, size, name } = props
    return (
        <li className="menu-list-item py-2 px-2 font-poppins" style={{ fontSize: size }}>
            {(link !== '')
                ? <a className="hover:text-secundaryColor" href={`/${link}`} >{name}</a>
                : name}
        </li>
    )
}