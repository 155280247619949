import { navValues } from '../../utils/json/Navbar'
import { contactData } from '../../utils/json/Contact'
import { InfoContactI } from '../../interfaces'
import { ListItem, SocialMediaIcons } from "../../atoms"


import logo from "../../assets/img/header/innovacion.png";

import './style.css'

export function FooterComponent(): JSX.Element {
    const info: InfoContactI[] = contactData.contact.info;
    const socialMedia: InfoContactI[] = contactData.contact.socialmedia;

    return (
        <div className="footer px-8 pt-16">
            <div className="grid grid-cols-3 gap-4">
                <div>
                    <img className="m-auto" src={logo} alt="aboutUsImg" width="250"></img>
                </div>
                <div className="p-8 m-auto">
                    <ul className="text-ligthColor font-bold">
                        {navValues.map((item, index) => {
                            return <ListItem key={index} name={item.name} link={item.link} size="25px" />
                        })}
                    </ul>
                </div>
                <div className="p-8 m-auto">
                    <ul className="text-ligthColor">
                        {info.map((item, index) => {
                            return <ListItem key={index} name={item.info} link={item.link} size="16px" />
                        })}
                    </ul>
                    <div className="my-4">
                        {socialMedia.map((item, index) => {
                            return <SocialMediaIcons key={index} name={item.name} link={item.link} info={item.info} />
                        })
                        }
                    </div>
                </div>
            </div>
            <div className="text-center text-ligthColor p-4 text-sm">
                <p>© 2022 SYMBTECH, All rights reserved by SymbtechSolutions.</p>
            </div>
        </div>
    )
}