import { useEffect } from 'react'
import { init } from "ityped";
import { TypedPropsI } from '../../interfaces';

import './style.css'

export function TypedAtom(props: TypedPropsI) {
    useEffect(() => {
        const myElement: Element = document.querySelector("#myElement")!
        init(myElement, {
            showCursor: true,
            strings: props.data,
            backDelay: 800
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div id="myElement" className="inline-block font-bold text-secundaryColor"></div>
    );
}