import { serviceData } from '../../utils/json/Service'
import { ParagraphTittle, BouncingImage } from '../../atoms'

import { ServiceItemI } from '../../interfaces'

import semiColonBackround from '../../assets/backgrounds/SemiColonDegradado.png'
import cuadradoBackround from '../../assets/backgrounds/CuadradoPunteo.png'

import './style.css'
import { useState } from "react";

export function ServicesComponent(): JSX.Element {
    const services = serviceData.services

    function ServiceTableInfo({ image, service, description }: ServiceItemI): JSX.Element {
        const img = require(`../../assets/img/services/${image}`);

        const [hover, setHover] = useState(false)

        return (
            <div className="my-4 px-4" onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <BouncingImage hover={hover} img={img.default} timeDuration='2s' width={70} />
                <div className="inline-block w-9/12 mx-4">
                    <ParagraphTittle tittle={service} tittleSize="1.7rem" text={description} textSize="16px" />
                </div>
            </div>
        )
    }

    return (
        <div className="my-8">
            <div className="backgroundServices">
                <img id="imgSemiColon" src={semiColonBackround} alt="backround" />
                <img id="imgCuadrado" src={cuadradoBackround} alt="backround" />
            </div>
            <div className="text-center w-1/2 mx-auto">
                <ParagraphTittle tittle={serviceData.title} tittleSize="3rem" text={serviceData.paragraph} textSize="20px" />
            </div>
            <div className="grid grid-cols-3 px-16 pr-10 py-8 mx-auto gap-4">
                {services.map((item, index) => {
                    return (
                        <ServiceTableInfo 
                            key={item.service} 
                            service={item.service} 
                            description={item.description} 
                            image={item.image} 
                        />
                    )
                })}
            </div>
        </div>
    )
}