import { useRef } from "react";

import { ParagraphTittle } from "../../atoms"

import { useOnScreen } from '../../utils/functions'
import { textData } from '../../utils/json/AboutUs'

import coheteEspacial from "../../assets/img/header/cohete-espacial.png";

import './style.css'
export function AboutUsComponent(): JSX.Element {
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen({ ref: ref, once: true })
    let animate = false
    if (isVisible) {
        animate = true
    }
    return (
        <div ref={ref} className="grid grid-cols-2 px-12 my-8">
            <div className={`${animate ? 'animate-left' : ''}`}>
                <img className="m-auto" src={coheteEspacial} alt="aboutUsImg" width="250"></img>
            </div>
            <div className={`${animate ? 'animate-right' : ''}`}>
                <ParagraphTittle tittle={textData.tittle} tittleSize="3rem" text={textData.text} textSize="1.3rem" />
            </div>
        </div>
    )
}