import { textData } from '../../utils/json/Header'
import { TypedAnimation, BouncingImage } from '../../atoms'

import headerImage from "../../assets/img/header/HeaderImage.png";
import coheteEspacial from "../../assets/img/header/cohete-espacial.png";
import circleBackround from '../../assets/backgrounds/CircleDegradado.png'

import './style.css'

export function HeaderComponent(): JSX.Element {
    const typed: string[] = textData.typed;

    function ImageHeader(): JSX.Element {
        return (
            <div className="px-12 text-center self-center"> 
                <img className="absolute mr-auto my-2" src={headerImage} alt="headerImage" width="620" />
                <BouncingImage hover={true} img={coheteEspacial} timeDuration='3s' width={270} />
            </div>
        )
    }

    return (
        <div className="px-12 py-8 grid grid-flow-col grid-cols-2 grid-rows-1 gap-4 my-8">
            <div className="backgroundContainer">
                <img id="imgCircle-1" src={circleBackround} alt="backround" />
                <img id="imgCircle-2" src={circleBackround} alt="backround" />
            </div>
            <div className="py-8 px-8 text-left self-center">
                <div className="headerParagraph font-poppins text-primaryColor font-bold h-32">
                    {textData.title}
                    <div className="inline-block mx-3">
                        <TypedAnimation data={typed} />
                    </div>
                </div>
                <div className="my-6 w-4/5">
                    <p className="text-xl font-extralight my-4 font-poppins text-primaryColor">{textData.paragraph}</p>
                    <div className="btn-container my-8">
                        <button className="bg-primaryColor hover:bg-white hover:text-primaryColor text-white font-bold py-2 px-4 rounded-full mx-8">Contactanos</button>
                        <button className="bg-secundaryColor hover:bg-white hover:text-primaryColor text-white font-bold py-2 px-4 rounded-full">Portafolio</button>
                    </div>
                </div>
            </div>
            <ImageHeader />
        </div>
    );
}
