import { BoucingPropsI } from "../../interfaces";
import './style.css'

export function BouncingImageAtom({ hover, timeDuration, width, img }: BoucingPropsI) {
    return (
        <div className={`animated bounce ${hover ? 'animated-start bounce-start' : ''}`}
            style={{ animationDuration: timeDuration }}>
            <img src={img} alt="img" width={width} />
        </div>
    );
}