
import { ParagraphTitlePropsI } from '../../interfaces';

import './style.css'

export function ParagraphTitleAtom({ tittle, tittleSize, textSize, text }: ParagraphTitlePropsI): JSX.Element {

    return (
        <div>
            <h1 className="font-poppins text-tittleColor font-extrabold" style={{ fontSize: tittleSize }}>{tittle}</h1>
            <p className="font-poppins text-primaryColor font-extralight my-2"
                style={{ fontSize: textSize }}
                dangerouslySetInnerHTML={{ __html: text }}>
            </p>
        </div>
    )
}