import {
    NavBar,
    Header,
    Services,
    AboutUs,
    Technologies,
    ContactForm,
    Footer
} from '../../components'

export function HomePage(): JSX.Element {
    return (
        <>
            <NavBar />
            <Header />
            <Services />
            <AboutUs />
            <Technologies />
            <ContactForm />
            <Footer />
        </>
    );
}
