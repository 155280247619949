import { HeaderTextI } from "../../interfaces";

export const textData: HeaderTextI = {
    'title': 'Preparate para impulsar tu',
    'paragraph':'Contactanos y conoce más sobre nuestros servicios que te ayudaran a crecer tu negocio ',
    'typed': [
        'Idea',
        'Proyecto',
        'Negocio',
        'Marca'
    ]
}