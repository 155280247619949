import { ServicesDataI } from "../../interfaces";

export const serviceData: ServicesDataI = {
    'title': 'Nuestros servicios',
    'paragraph': 'En <strong>SymbTech</strong> somos expertos en el desarrollo de software, integaciones tecnologias, paginas web y más servicios del area informatica',
    'services': [
        {
            'service': 'Paginas web',
            'description': 'Desarrolla tu paginas web o landing page para tu negocio o proyecto',
            'image':'WebPage.png'
        },
        {
            'service': 'Aplicativos web',
            'description': 'Desarrolla tu aplicativo con las mejores tecnologias y disponibilidad',
            'image':'WebApp.png'
        },
        {
            'service': 'Aplicativos moviles',
            'description': 'Desarrolla tu app para tu negocio con el mejor diseño y funcionamiento',
            'image':'AppMovile.png'
        },
        {
            'service': 'Analisis de datos',
            'description': 'Genera una buena analitica para que conozcas como puedes seguir mejorando tu proyecto',
            'image':'AnaliticaDatos.png'
        },
        {
            'service': 'Machine Learning',
            'description': 'Deja que la tecnologia te ayude con tareas que son robustas',
            'image':'MachineLearning.png'
        }
    ]

}