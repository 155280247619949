
import { ImageInfinityScrollI } from '../../interfaces'
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'

export function ImageInfinityScrollAtom(props: ImageInfinityScrollI): JSX.Element {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 1,
        cssEase: "linear"
    };
    return (
        <div className="w-11/12 mx-auto">
            <div className="my-16">
                <Slider {...settings}>
                    {props.image.map((item, index) => {
                        return (
                            <div key={index}>
                                <img className="mx-8" src={item} alt="img" />
                            </div>)
                    })}
                </Slider>
            </div>
        </div>
    )

}