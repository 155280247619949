import emailjs from 'emailjs-com';
import { accessMail } from '../../utils/json/Access'

import { FormEmailJsI } from '../../interfaces';

export function sendMail(formContact: FormEmailJsI) {
    let params: Record<string, string> = {
        'from_name': formContact.name,
        'message': formContact.message,
        'from_email': formContact.email,
        'from_phone': formContact.phone,
        'from_company': formContact.company
    }
    return emailjs.send(accessMail.serviceId, accessMail.templateId, params, accessMail.userId)
        .then((response) => {
            return response.status
        }, (err) => {
            console.log(err)
            return false
        });
}