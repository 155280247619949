import { useEffect, useState } from 'react'
type screenParams = {
    ref: any
    once: boolean
}

export function useOnScreen(props:screenParams) {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    )

    useEffect(() => {
        if (props.once) {
            if (!isIntersecting) {
                observer.observe(props.ref.current)
            }
        } else {
            observer.observe(props.ref.current)
        }
        return () => { observer.disconnect() }
    })

    return isIntersecting
}