import { contactData } from '../../utils/json/Contact'
import { ParagraphTittle } from '../../atoms'

import { ContactForm } from '../../common'


import './style.css'

export function ContactComponent(): JSX.Element {
    return (
        <div className="contactForm py-8 ">
            <div className="text-center w-1/2 mx-auto">
                <ParagraphTittle tittle={contactData.tittle} tittleSize="3.5rem" text='' textSize="20px" />
                <div className="card p-4 pr-8">
                    <ContactForm />
                </div>
            </div>
        </div>
    )
}