import { TechnologyI } from "../../interfaces";

export const technologyData: TechnologyI = {
    'tittle': 'Tecnologias',
    'text': 'En nuestra empresa, queremos siempre estar a la vanguardia tecnologica, es por eso que nuestros desarrollos se realizan usando la mejor tecnologia',
    'technology': [
        {
            'name': 'AWS',
            'img': 'aws.png'
        },
        {
            'name': 'Digital Ocean',
            'img': 'DigitalOcean.png'
        },
        {
            'name': 'React',
            'img': 'react.png'
        },
        {
            'name': 'Docker',
            'img': 'docker.png'
        },
        {
            'name': 'Flutter',
            'img': 'flutter.png'
        },
        {
            'name': 'Spring Boot',
            'img': 'spring-boot.png'
        }
    ]
}