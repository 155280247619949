import { useState } from 'react'
import { connect } from 'react-redux'

import setLoadingAction from '../../redux/actions/loadingAction'

import { sendMail } from '../../services/mail/sendMail.service'

import { FormContactI, ContactFormParamsI, FormEmailJsI, AlertPropsI } from '../../interfaces'
import { contactData } from '../../utils/json/Contact'

import { Input, AlertMessage } from '../../atoms'

function ContactFormCommon({ setLoadingActio }: any): JSX.Element {

    const frmContact: ContactFormParamsI = { name: '', lastname: '', company: '', phone: '', mail: '', message: '' };
    const alertState: AlertPropsI = { type: '', message: '' };
    const [contact, setContact] = useState<any>(frmContact);
    const [alertMessage, setAlertMessage] = useState<AlertPropsI>(alertState);
    const form = contactData.form

    const _handleChange = (e: any) => {
        const { name, value } = e.target;
        setContact({ ...contact, [name]: value });
    };

    const _handleSubmit = async (e: any) => {
        e.preventDefault();

        let formSender: FormEmailJsI = {
            name: contact.name + ' ' + contact.lastname,
            company: contact.company,
            email: contact.mail,
            phone: contact.phone,
            message: contact.message
        }
        setLoadingActio(true)
        const response = await sendMail(formSender)
        if (response === 200) {
            setLoadingActio(false)
            setAlertMessage({ type: 'SUCCESS', message: 'Correo enviado satisfactoriamente' })
            setContact({ ...frmContact })
        } else {
            setLoadingActio(false)
            setAlertMessage({ type: 'FAILED', message: 'No se ha podido completar el proceso intentelo más tarde' })
        }
    }

    function InputAtomReturn(item: FormContactI, index: number): JSX.Element {
        return < Input
            key={index}
            name={item.name}
            type={item.type}
            value={contact[`${item.name}`]}
            placeholder={item.placeholder}
            label={item.label}
            required={item.required}
            onChange={_handleChange}
        />
    }
    return (
        <form action="" className="my-8 px-4" onSubmit={_handleSubmit}>
            <div className="grid grid-cols-2 gap-2">
                {form.slice(0, 4).map((item, index) => {
                    return InputAtomReturn(item, index)
                })
                }
            </div>
            <div className="grid grid-cols-1 gap-2">
                {form.slice(4, form.length).map((item, index) => {
                    return InputAtomReturn(item, index)
                })
                }
            </div>
            <div className='my-4'>
                <button type='submit' className='bg-primaryColor hover:bg-ligthColor hover:text-primaryColor text-white font-poppins py-3 px-16 font-bold text-2xl rounded-md'>
                    Enviar
                </button>
            </div>
            {alertMessage.type
                ? <AlertMessage type={alertMessage.type} message={alertMessage.message} setAlertMessage={setAlertMessage} />
                : null
            }
        </form>
    )
}

const _mapDispatchToProps = (dispatch: any) => ({
    setLoadingActio: (loadingSelected: boolean) => dispatch(setLoadingAction(loadingSelected))
})

export default connect(null, _mapDispatchToProps)(ContactFormCommon)