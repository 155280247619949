import { InputPropsI } from '../../interfaces'

import './style.css'

export function InputAtom(props: InputPropsI): JSX.Element {

    return (
        <div>
            <label htmlFor={props.name} className="font-poppins p-4 text-primaryColor font-bold text-2xl float-left">{props.label}</label>
            {(props.type !== 'textarea')
                ? <input className="font-poppins px-4 py-3 mx-3 text-primaryColor"
                    id={props.name}
                    name={props.name}
                    type={props.type}
                    placeholder={props.placeholder}
                    required={props.required}
                    onChange={props.onChange}
                    value={props.value}
                    autoComplete="off">
                </input>
                : <textarea className="font-poppins px-4 py-3 mx-3 text-primaryColor"
                    id={props.name}
                    name={props.name}
                    placeholder={props.placeholder}
                    required={props.required}
                    rows={5}
                    value={props.value}
                    onChange={props.onChange}
                    autoComplete="false">
                </textarea>}
        </div>
    )
}