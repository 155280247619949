import ReactLoading from 'react-loading';

import { connect } from 'react-redux';

import './style.css'

function LoadingAtom(props: any) {
    return (
        (props.loadingState.loadingSelected)
            ? <div id="loading" className='loading'>
                <ReactLoading
                    className="m-auto flex justify-items-center justify-center items-center"
                    type="bubbles"
                    color="#3C6E71"
                    height={'100%'}
                    width={300} />
            </div>
            : null
    )
}
const mapStateToProps = (state: any) => ({
    ...state
})
export default connect(mapStateToProps, {})(LoadingAtom)